import DOMPurify from 'dompurify';
import moment from 'moment-timezone';

import { IVoucherWalletResponse } from 'api/voucherWallet';
import { DATE_FORMAT, TIME_FORMAT } from 'appConstants';

export const restrictNonIntPoints = event => {
  if (event.key === '.' || event.key === 'e' || event.key === '-') {
    event.preventDefault();
  }
};

export const getBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const sanitizeMarkup = (text: string) => {
  return { __html: DOMPurify.sanitize(text) };
};

export const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export const memoizeInputComponent = self => {
  return (key, f) => {
    self.__memoizeddInputFuncGenerator = self.__memoizeddInputFuncGenerator
      ? self.__memoizeddInputFuncGenerator
      : {};
    self.__memoizeddInputFuncGenerator[key] = self.__memoizeddInputFuncGenerator[key]
      ? self.__memoizeddInputFuncGenerator[key]
      : f;
    return self.__memoizeddInputFuncGenerator[key];
  };
};

export const unmemoizeInputComponent = self => {
  return key => {
    if (key === undefined) {
      self.__memoizeddInputFuncGenerator = {};
    } else {
      if (self.__memoizeddInputFuncGenerator) {
        delete self.__memoizeddInputFuncGenerator[key];
      }
    }
  };
};

export const formatNumber = function (number, n?, x?) {
  return !number || isNaN(number) ? 0 : number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getDate = time => {
  if (!time) {
    return '';
  }
  return moment(time).utc().format(DATE_FORMAT);
};

export const getTime = time => {
  if (!time) {
    return '';
  }
  return moment(time).utc().format(TIME_FORMAT);
};

export const chooseTagColor = state =>
  ({
    active: '#9bcf00',
    approved: '#25a9e5',
    inactive: '#f5a623',
    ended: '#c23934'
  }[state.toLowerCase()] || '#cccccc');

export const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    'INLINE_STYLE_BUTTONS',
    'BLOCK_TYPE_BUTTONS',
    'LINK_BUTTONS',
    'BLOCK_TYPE_DROPDOWN',
    'HISTORY_BUTTONS'
  ],
  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    { label: 'Strikethrough', style: 'STRIKETHROUGH' }
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal', style: 'unstyled' },
    { label: 'Heading Large', style: 'header-one' },
    { label: 'Heading Medium', style: 'header-two' },
    { label: 'Heading Small', style: 'header-three' }
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
    { label: 'Blockquote', style: 'blockquote' }
  ]
};

export const scrollTo = (top = 0, left = 0) => {
  try {
    window.scrollTo({ top, left, behavior: 'smooth' });
  } catch (err) {
    if (err instanceof TypeError) {
      window.scroll(top, left);
    } else {
      throw err;
    }
  }
};

export const removeEmptyFields = jsonValues =>
  Object.entries(jsonValues).reduce(
    (a, [k, v]) => (v == null || v === '' ? a : { ...a, [k]: v }),
    {}
  );

/**
 *
 * @param obj
 * Replaces the empty-string values of the arg object with null, recursively.
 */
export const replaceEmptyStringsWithNull = (obj: Object) => {
  const shallowCopy = Array.isArray(obj) ? [...obj] : { ...obj };

  for (const [key, value] of Object.entries(shallowCopy)) {
    if (typeof value === 'string' && value === '') {
      shallowCopy[key] = null;
    } else if (value?.constructor === Object || value?.constructor === Array) {
      shallowCopy[key] = replaceEmptyStringsWithNull(value);
    }
  }

  return shallowCopy;
};

/**
 * @param {number} v
 * @param {string} singular
 */
export const pluralize = (v, singular) => {
  return `${v} ${singular}${v === 1 ? '' : 's'}`;
};

export const numberWithCommas = x => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return '0';
  }
};

export const getDeepKeys = obj => {
  let keys = [];
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
        let subKeys = getDeepKeys(obj[key]);
        keys = keys.concat(
          subKeys.map(subkey => {
            return key + '.' + subkey;
          })
        );
      } else if (Array.isArray(obj[key])) {
        for (let i = 0; i < obj[key].length; i++) {
          let subKeys = getDeepKeys(obj[key][i]);
          keys = keys.concat(
            subKeys.map(subkey => {
              return `${key}[${i}].${subkey}`;
            })
          );
        }
      } else {
        keys.push(key);
      }
    }
  }
  return keys;
};

export const isRichTextEmpty = richText => {
  return (
    richText === '<p><br></p>' || richText === '<p><br></p> ' || richText === 'N.A' || !richText
  );
};

export const findCategoryWithParentLabel = (categories, id, parentLabelKey) => {
  let parentLabel = null;
  let found = null;

  for (let index = 0; index < categories.length; index++) {
    const category = categories[index];
    if (category.id === id) {
      found = category;
      break;
    }

    if (!found && category.children) {
      for (let subIndex = 0; subIndex < category.children.length; subIndex++) {
        const child = category.children[subIndex];
        if (child.id === id) {
          parentLabel = category[parentLabelKey];
          found = child;
          break;
        }
      }
    }
  }

  return { found, parentLabel };
};

export const calulatePercentage = (number: number, total: number, decimalPoint: number) => {
  if (total < 1) {
    return '0';
  }
  return ((number / total) * 100).toFixed(decimalPoint) + '%';
};

export const cdnURL = <S extends string>(path: S) => {
  const baseName = `https://cdn.perxtech.net/content/dashboard`;
  return `${baseName}/${path}` as `${typeof baseName}/${S}`;
};

export const useremapResponse = (data: IVoucherWalletResponse[]) => {
  return data.map(item => ({
    id: item.id,
    name: item.name,
    tag_id: item.tag_id,
    tag_name: item.tag_name,
    images: item.images[0]?.url || '',
    sort_order: item.sort_order
  }));
};
